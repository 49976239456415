







































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { H3Avatar, H3Empty } from '@authine/awesome-ui';

import { getActionTextFromStorage, getImageUrl } from '@WorkPlatform/common/utils';

import { CardInformation } from '@cloudpivot7-business-components/pc';

import moment from 'moment';
import { EClientType, FlowActions } from '@WorkPlatform/common/const';
import { pageForward } from '@WorkPlatform/service/flow-center';

@Component({
  name: 'todo-list',
  components: {
    AAvatar: H3Avatar,
    AEmpty: H3Empty,
    CardInformation,
  },
})
export default class ComponentName extends Vue {
  @Prop({ default: [] }) list!: any[];

  @Prop({ default: () => 0 }) tabKey?: number;

  emptyImgUrl = require('@/assets/images/empty.svg');

  get IsDingTalk () {
    return sessionStorage.IS_DINGTALK === 'true';
  }

  get isFreeLoginPlatform () {
    return (
      sessionStorage.IS_DINGTALK === 'true' ||
      sessionStorage.IS_WECHAT === 'true'
    );
  }

  get ISWECHAT () {
    return sessionStorage.IS_WECHAT === 'true';
  }

  get userAvatar () {
    return {
      backgroundImage: `url(${require('@/assets/images/tx.png')})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: '32px',
      height: '32px',
    };
  }

  get urgingText () {
    return getActionTextFromStorage(FlowActions.Urge);
  }

  getImgUrl (img) {
    return getImageUrl(img);
  }

  calcTimeout (item: any) {
    if (item.timeoutStatus) {
      return item.allowedTime ? this.timeOut(item.allowedTime) : 0;
    }
    return item.usedtime ? this.formatSeconds(item.usedtime / 1000) : 0;
  }

  /**
   * 跳转待办详情
   */
  clickHandler (item: any) {
    pageForward(item, EClientType.PC);
  }

  formatSeconds (value: any): string {
    if (!value) return '--';
    const unit: number = 10;
    const aHour: number = 60;
    const aDay: number = 24;
    const empty: number = 0;
    let theTime: number = parseInt(value, unit); // 需要转换的时间秒
    let theTime1: number = 0; // 分
    let theTime2: number = 0; // 小时
    let theTime3: number = 0; // 天

    if (theTime > aHour) {
      theTime1 = parseInt(String(theTime / aHour), unit);
      theTime = parseInt(String(theTime % aHour), unit);
      if (theTime1 > aHour) {
        theTime2 = parseInt(String(theTime1 / aHour), unit);
        theTime1 = parseInt(String(theTime1 % aHour), unit);
        if (theTime2 > aDay) {
          // 大于24小时
          theTime3 = parseInt(String(theTime2 / aDay), unit);
          theTime2 = parseInt(String(theTime2 % aDay), unit);
        }
      }
    }
    let result = '';
    if (theTime > empty) {
      result = '' + parseInt(theTime + '', unit) + '秒';
    }
    if (theTime1 > empty) {
      result = '' + parseInt(theTime1 + '', unit) + '分' + result;
    }
    if (theTime2 > empty) {
      result = '' + parseInt(theTime2 + '', unit) + '小时' + result;
    }
    if (theTime3 > empty) {
      result = '' + parseInt(theTime3 + '', unit) + '天' + result;
    }

    return result;
  }

  get userInfo () {
    let publicUserInfo: any = localStorage.getItem('publicUserInfo');
    if (publicUserInfo) {
      publicUserInfo = JSON.parse(publicUserInfo) || {};
      const userInfo: any = publicUserInfo || {};
      return userInfo;
    }
    return {
      name: '-',
      mobile: '-',
    };
  }

  timeOut (value: string) {
    const oldTime: any = moment(value, 'YYYY-MM-DD hh:mm:ss').utc(); // new Date(str) safari incapitble
    const newTime: any = new Date().getTime();
    const timeout: any = newTime - oldTime;
    const unit: number = 1000;
    return `已超时${this.formatSeconds(timeout / unit)}`;
  }

  get token () {
    return localStorage.getItem('token');
  }
}
